import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/js/rem.js"; //引入rem.js
import "./assets/css/reset.css"; //引入重置样式

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

//引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");