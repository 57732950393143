<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default 
{
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
#app {
  min-height: 100vh;
}
</style>
